import React from 'react';
import PostList from '../components/Posts/PostList';

function Home() {
    return (
        <div className="Home">
            <PostList />
        </div>
    )

}

export default Home